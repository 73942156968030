import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// const socket = io('https://chatbotbuilder.awschatbot.online');
const socket = io('https://chatbotbuilder.awschatbot.online');
const ApiURL='https://chatbotbuilder.awschatbot.online/api'

const Chat = () => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState('');
    const [username, setUsername] = useState('');
    const [users, setUsers] = useState([]); // List of users from users.json
    const [recipientId, setRecipientId] = useState(''); // Selected recipient

    useEffect(() => {
        if (isLoggedIn) {
            // Fetch users from the server after logging in
            fetch(`${ApiURL}/users`)
                .then((response) => response.json())
                .then((data) => setUsers(data))
                .catch((error) => console.error('Error fetching users:', error));

            // Listen for private messages from the server
            socket.on('privateMessage', ({ senderId, message }) => {
                setMessages((prevMessages) => [...prevMessages, `From User ${senderId}: ${message}`]);
            });

            // Listen for updates to the user list
            socket.on('updateUsers', (updatedUsers) => {
                setUsers(updatedUsers);
            });

            // Cleanup on component unmount
            return () => {
                socket.off('privateMessage');
                socket.off('updateUsers');
            };
        }
    }, [isLoggedIn]);

    const login = async () => {
        if (userId && username) {
            try {
                // Send login request to the server
                const response = await fetch(`${ApiURL}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: userId, username }),
                });

                const data = await response.json();
                if (data.message === 'Login successful') {
                    setIsLoggedIn(true);
                    // Register the user with Socket.IO after login
                    socket.emit('register', userId);
                } else {
                    alert(data.message);
                }
            } catch (error) {
                console.error('Error logging in:', error);
            }
        } else {
            alert('Please enter a valid user ID and username');
        }
    };

    const sendMessage = async () => {
        if (message && recipientId) {
            // Emit the message as a private message
            socket.emit('privateMessage', {
                senderId: userId,
                recipientId,
                message,
            });

            // Optionally display the sent message in the sender's chat window
            setMessages((prevMessages) => [...prevMessages, `To User ${recipientId}: ${message}`]);
            setMessage(''); // Clear the input
        } else {
            alert('Please select a recipient and enter a message.');
        }
    };

    if (!isLoggedIn) {
        // Render the login form if the user is not logged in
        return (
            <div style={{ padding: '20px' }}>
                <h2>Login</h2>
                <input
                    type="text"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    placeholder="Enter your user ID"
                    style={{ marginRight: '10px' }}
                />
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Enter your username"
                    style={{ marginRight: '10px' }}
                />
                <button onClick={login}>Login</button>
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <h2>Chat with Socket.IO - Welcome, {username}</h2>

            {/* Dropdown to select the recipient */}
            <select onChange={(e) => setRecipientId(e.target.value)} value={recipientId}>
                <option value="" disabled>Select recipient</option>
                {users
                    .filter(user => user.id !== userId) // Don't show current user in the list
                    .map((user) => (
                    <option key={user.id} value={user.id}>
                        {user.username}
                    </option>
                ))}
            </select>

            <div style={{ marginBottom: '10px', height: '200px', overflowY: 'scroll', border: '1px solid #ccc' }}>
                {messages.map((msg, index) => (
                    <div key={index}>{msg}</div>
                ))}
            </div>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here..."
                style={{ marginRight: '10px' }}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default Chat;
