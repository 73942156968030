// src/App.js

import React from 'react';
import Chat from './Chat';

const App = () => {
    return (
        <div>
            <Chat />
        </div>
    );
};

export default App;
